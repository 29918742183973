import React from 'react';
import './label.css';

export const Label = React.forwardRef(({ id, name, company }, ref) => {
    return (
        <div className="etiqueta" id="etiquetaParaImprimir" ref={ref}>
            <img src="expo-pacs24-logo.png" alt="Logo" className="logo" />
            
            <div>
                <p><strong>ID:</strong> <span id="etiquetaId">{id}</span></p>
                <p><strong>Nombre:</strong> <span id="etiquetaNombre">{name}</span></p>
                <p><strong>Compañía:</strong> <span id="etiquetaCompania">{company}</span></p>
            </div>
        </div>
    );
});