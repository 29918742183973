import React from 'react';
import { TextField, Button } from '@mui/material';
import { Label } from './components/LabelComponent';
import ReactToPrint from 'react-to-print';

function CheckIn() {
    const [id, setId] = React.useState('');
    const [showLabel, setShowLabel] = React.useState(false);
    const [name,setName] = React.useState('');
    const [company,setCompany] = React.useState('');
    const componentRef = React.useRef();
    const [idLabel, setIdLabel] = React.useState('');

    function getUserData(id){
        // Deberas hacer una peticion get al endpoint `https://qw0uemu6dj.execute-api.us-east-1.amazonaws.com/user`
        // y le debras pasar en el body el id del usuario que se quiere obtener

        fetch('https://qw0uemu6dj.execute-api.us-east-1.amazonaws.com/user', {
            method: 'POST',
            body: JSON.stringify({id: id})
        })
        .then(response => response.json())
        .then(data => {
            setName(data.full_name);
            setCompany(data.company);
            setIdLabel(data.id);
            setShowLabel(true);
        })
        .catch(error => {
            console.error('Error:', error);
        });


    }

    const handlePrint = () => {
        if (id === '') {
            alert('ID is required');
            return;
        }
        getUserData(id);
    };

    return (
        <div className="my-container">
            <form className='formulario-container'>
                <TextField id='id' label='ID' value={id} onChange={(e) => setId(e.target.value)} />
                <Button onClick={handlePrint} variant='contained' color='primary'>Registrar</Button>
                <Button variant='contained' color='secondary' onClick={() => {window.location.href = 'https://expo-pacs24.g-tech.io/';}}>Regresar</Button>
            </form>
            {showLabel && (
                <div className='label-container'>
                    <Label ref={componentRef} id={idLabel} name={name} company={company} />
                    <ReactToPrint
                        trigger={() => 
                        <Button variant='contained' color='secondary'>Imprimir</Button>}
                        content={() => componentRef.current}
                    />
                </div>
            )}
        </div>
    );
}

export default CheckIn;