import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

function MultiSelectAutocomplete({ options, value, onChange, label, id, fullWidth }) {
    return (
        <div>
            <Autocomplete
                multiple
                id={id}
                options={options}
                getOptionLabel={(option) => option.label}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                fullWidth={fullWidth}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        // Obtenemos todas las props necesarias para el Chip
                        const tagProps = getTagProps({ index });
                        // Pasamos la key y el resto de props de manera explícita
                        return <Chip label={option.label} key={tagProps.key} {...tagProps} />;
                    })
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} placeholder={label} />
                )}
                sx={{ mb: 2 }}
            />
        </div>
    );
}

export default MultiSelectAutocomplete;
