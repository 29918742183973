import React from 'react';
import './App.css';
import { TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, InputLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import MultiSelectAutocomplete from "./components/CustomComboBox";

const areaInteresOptions = [
    { label: "Desarrollo de Software", id: 1 },
    { label: "RH", id: 2 }
]


function Formulario() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState('');
  const [host, setHost] = React.useState('');
  const [area_interes, setAreaInteres] = React.useState([]);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [position, setPosition] = React.useState('');
  
  const [nameError, setNameError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleHostChange = (event) => {
    setHost(event.target.value);
  }

  function validator(){
    if(name === '' || phone === '' || email === '' || company === '' || position === '' || 
      area_interes === '' || host === '' || value === '' || phoneError){
      return false;
    }
    return true;
  }

  function clearForm(){
    setName('');
    setPhone('');
    setEmail('');
    setCompany('');
    setPosition('');
    setAreaInteres([]);
    setHost('');
    setValue('');
  }

  function handleSubmit(event) {
    event.preventDefault();

    if(!validator()){
      enqueueSnackbar('Por favor llena todos los campos', { variant: 'error' });
      return;
    }

    let areas = new Array();
    area_interes.forEach((area) => {
      areas.push(area.label);
    });

    const body = {
      full_name: name.trimStart().trimEnd(),
      cellphone: phone,
      email: email,
      company: company.trimStart().trimEnd(),
      position: position.trimStart().trimEnd(),
      interest_areas: areas,
      host: host,
      assist_2023: value
    }


    console.log(body);
    clearForm();


    // Hay que hacer una peticion a la API para enviar los datos del formulario
    //Este es el endpoint: https://d6w4xc4892.execute-api.us-east-1.amazonaws.com/dev/
    // metodo POST y el body debe ser un JSON con los datos del formulario

    fetch('https://d6w4xc4892.execute-api.us-east-1.amazonaws.com/dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      enqueueSnackbar('Operación exitosa', { variant: 'success' });
      // quiero que me redirecciones a la ruta `https://expo-pacs24.g-tech.io/` cuando la operación sea exitosa
      window.location.href = 'https://expo-pacs24.g-tech.io/check-in';
    })
    .catch((error) => {
      console.error('Error:', error);
      enqueueSnackbar('Ocurrió un error', { variant: 'error' });
    });


  }

  return (
    <div className='formulario-container' >
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        {/* debe haber un encabezado con la leyenda "Expo PACS 2024" con la palabra PCAS en negritas y debera estar centrado*/}
        <div className='custom-container'>
          <img src="expo-pacs24-logo.png" alt="Logo" className="logo centrado" />
        </div>
        <TextField 
          id="name" 
          label="Nombre Completo" 
          variant="outlined" 
          value={name}
          fullWidth 
          margin="normal" 
          onChange={(e) => {
            setNameError(!/^[a-zA-Z\s]*$/.test(e.target.value));
            setName(e.target.value);
            // Aquí puedes agregar la lógica para manejar el estado de error basado en la validación
          }} 
          required
          inputProps={{ 
            pattern: "^[a-zA-Z\\s]*$" 
          }}
          error={nameError}
          helperText={nameError ? "El nombre no debe contener números ni caracteres especiales." : ""}
        />
      <TextField 
          id="phone" 
          label="Número Telefónico" 
          type="phone" 
          value={phone}
          variant="outlined" 
          fullWidth 
          margin="normal" 
          inputProps={{ maxLength: 10, pattern: "[0-9]*" }} 
          error={phoneError}
          helperText={phoneError ? "El número debe tener hasta 10 dígitos." : ""}
          onChange={(e) => {
            setPhoneError(e.target.value.length > 10 || !/^\d*$/.test(e.target.value));
            setPhone(e.target.value);
          }}
          required
        />
      <TextField 
          id="email" 
          label="Correo Electrónico" 
          type="email" 
          value={email}
          variant="outlined" 
          fullWidth 
          margin="normal" 
          onChange={(e) => {
            setEmail(e.target.value.trim());
            setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value.trim()));
          } 
          } // Usa la nueva función de manejo de cambio
          required
          error={emailError} // Usa el estado de error del correo electrónico
          helperText={emailError ? "Por favor ingresa un correo electrónico válido." : ""}
        />
      <TextField id="company" label="Compañia" variant="outlined" value={company} fullWidth margin="normal" onChange={(e) => setCompany(e.target.value)} required/>
      <TextField id="position" label="Puesto" variant="outlined" value={position} fullWidth margin="normal" onChange={(e) => setPosition(e.target.value)} required/>
      
      <MultiSelectAutocomplete
        options={areaInteresOptions}
        fullWidth
        label="Área de Interés"
        id="inArea"
        value={area_interes}
        onChange={setAreaInteres}
        renderInput={(params) => <TextField {...params} label="Areas" variant="outlined" />}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="host">¿Quién te invitó al evento?</InputLabel>
        <Select
          labelId="host"
          id="host"
          label="Area de Interes"
          value={host}
          onChange={handleHostChange}
          required
        >
          <MenuItem value="Diana Ocón">Diana Ocón</MenuItem>
          <MenuItem value="Alejandra Márquez">Alejandra Márquez</MenuItem>
          <MenuItem value="Jaime López">Jaime López</MenuItem>
          <MenuItem value="Otro">Otro</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl component="fieldset" margin="normal" className="row">
        <FormLabel component="legend">¿Asististe en 2023?</FormLabel>
        <RadioGroup aria-label="correcto" name="assist" value={value} onChange={handleChange} required>
          <FormControlLabel value="si" control={<Radio />} label="Sí" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <div className="button-container">
        <Button variant="contained" color="primary" type="submit">
          Enviar
        </Button>
      </div>
    </form>
    </div>
  );
}

export default Formulario;